<template lang="pug">
article.episode
  // Episode's cover
  aside.episode__side
    .episode__cover
      img.episode__image(
        alt=""
        v-if="!episodeCoverUrl"
        src="/images/no-cover.png"
        srcset="/images/no-cover.png 2x"
      )
      img.episode__image(
        alt=""
        v-else
        :src="episodeCoverUrl"
        :srcset="episodeCoverUrlRetina + ' 2x'"
      )
      img.episode__image-blured(
        alt=""
        v-if="!episodeCoverUrl"
        src="/images/no-cover.png"
        srcset="/images/no-cover.png 2x"
      )
      img.episode__image-blured(
        alt=""
        v-else
        :src="episodeCoverUrl"
        :srcset="episodeCoverUrlRetina + ' 2x'"
      )

  // Episode main information
  header.episode__content
    .episode__top
      client-only
        PlayPauseButton.episode__play-pause(
          :isPlaying="isPlaying && status === 'playing'"
          @on-click="playPauseEpisode"
        )
      .episode__explicit-mobile(
        v-if="episodeData['itunes_explicit'] === 'yes'"
        )
          UiExplicitTag.episode__explicit-mobile-tag
      .episode__info
        h1.episode__title
          span(
            v-html="episodeData.title"
          )
          .episode__explicit-desktop(
            v-if="episodeData['itunes_explicit'] === 'yes'"
          )
            UiExplicitTag.episode__explicit-desktop-tag
        h2.episode__sub-title(
          v-if="podcastData['author_name']"
          v-html="podcastText"
        )
        .episode__metadata
          .episode__metadata-episode(v-if="episodeNumbers")
            span {{ episodeNumbers }}
          .episode__metadata-created
            CalendarIcon.episode__metadata-created-icon
            client-only {{ creationDate }}
          .episode__metadata-countdown
            ClockIcon.episode__metadata-countdown-icon
            span(v-if="!isPlaying || status === 'stopped'") {{ duration }}
            span(v-else) {{ countdown }}
    Share.episode__share(
      :podcastData="podcastData"
      :episodeData="episodeData"
    )
</template>

<script>
import { decode } from 'html-entities'
import { mapState, mapActions } from 'vuex'

import toast from '@/lib/toast'
import { formatTextDate } from '@/lib/utils'

import CalendarIcon from '@/components/icons/dashboard/Calendar'
import ClockIcon from '@/components/icons/dashboard/Clock'
import AudioPlayer from '@/components/ui/controls/AudioPlayer.vue'
import PlayPauseButton from '@/components/ui/controls/PlayPauseButton'

import Share from '@/components/pages/public-episode/share-and-distribution/index'

export default {
  components: {
    AudioPlayer,
    PlayPauseButton,
    CalendarIcon,
    ClockIcon,
    Share
  },

  props: {
    episodeCoverUrl: {
      type: String,
      default: ''
    },
    episodeCoverUrlRetina: {
      type: String,
      default: ''
    },
    episodeData: {
      type: Object,
      default: () => null
    },
    podcastData: {
      type: Object,
      default: () => null
    }
  },

  data () {
    return {
      isPlaying: false
    }
  },

  computed: {
    ...mapState('player', ['status', 'currentEpisode', 'countdown']),

    podcastSlug () {
      return this.$route.params.slug
    },

    seeMoreLinkIsDisplaying () {
      const description = decode(this.episodeData.description)
        .replace(/(<([^>]+)>)/gi, '')

      return description > 340
    },

    podcastText () {
      return `${this.podcastData.title} ${this.$t('single_words.by')} ${this.podcastData.author_name}`
    },

    episodeNumbers () {
      let season = this.episodeData.itunes_season
      let episode = this.episodeData.itunes_episode
      if (!parseInt(season) && !parseInt(episode)) {
        return ''
      }
      if (season > 0 && season < 10) { season = '0' + season.toString() }
      if (episode > 0 && episode < 10) { episode = '0' + episode.toString() }
      const seasonLetter = this.$t('single_words.season')[0]
      const episodeLetter = this.$t('single_words.episode')[0]
      if (!episode) {
        return `${seasonLetter}${season}`
      }
      if (!season) {
        return `${episodeLetter}${episode}`
      }
      return `${seasonLetter}${season} ${episodeLetter}${episode}`
    },

    duration () {
      let duration

      if (!this.episodeData.itunes_duration) {
        duration = this.durationFromAudioFile
      } else {
        duration = parseInt(this.episodeData.itunes_duration)
      }
      if (!duration) {
        return '--:--'
      }

      let result = ''
      let seconds = duration % 60
      let hours = Math.floor(duration / 60 / 60)
      let minutes = Math.floor(duration / 60) - (hours * 60)
      if (seconds >= 0 && seconds < 10) {
        seconds = `0${seconds}`
      }
      if (minutes >= 0 && minutes < 10) {
        minutes = `0${minutes}`
      }
      if (hours >= 0 && hours < 10) {
        hours = `0${hours}`
      }
      if (hours && hours !== '00') {
        result += hours + ':'
      }
      result += minutes + ':' + seconds
      return result
    },

    creationDate () {
      return formatTextDate(this.episodeData.pub_date, this.$i18n.locale)
    },

    isCurrentEpisode () {
      return this.currentEpisode && this.episodeData && this.currentEpisode.id === this.episodeData.id
    }
  },

  watch: {
    currentEpisode (newValue) {
      if (newValue && this.episodeData && this.episodeData.id === newValue.id) {
        this.isPlaying = true
      } else {
        this.isPlaying = false
      }
    },

    status (newValue) {
      this.isPlaying = newValue === 'playing' && this.isCurrentEpisode
    }
  },

  mounted () {
    if (this.isCurrentEpisode) {
      this.isPlaying = true
    }

    // Query param t
    if (this.$route.query.time) {
      this.setCurrentPlayingPodcast(this.podcastData)
      this.setCurrentPlayingEpisode(this.episodeData)
      const seconds = isNaN(this.$route.query.time) ? 0 : this.$route.query.time
      this.setSkipTo(seconds)
    }
  },

  methods: {
    ...mapActions('player', ['setPlayerStatus', 'setCurrentPlayingEpisode', 'setCurrentPlayingPodcast', 'setSkipTo']),

    async playPauseEpisode (action) {
      // Don't start playing m4a files on Safari
      // eslint-disable-next-line
      const ua = navigator.userAgent.toLowerCase() 
      if (ua.includes('safari')) {
        if (!ua.includes('chrome')) {
          // eslint-disable-next-line
          if (this.episodeData && this.episodeData.episode_asset && this.episodeData.episode_asset.endsWith('.m4a')) {
            toast(this.$t('toast_notifications.errors.audio_file__not_available'), 'danger')
            return
          }
        }
      }

      this.isPlaying = action
      if (action) {
        const playlist = await this.$store.dispatch('podcasts/getPlaylist', this.podcastData.slug) || []
        await this.$store.dispatch('player/setPlayList', playlist)

        this.setPlayerStatus('playing')
        this.setCurrentPlayingEpisode(this.episodeData)
        this.$emit('onPlayEpisode')
        return
      }
      this.setPlayerStatus('paused')
    }
  }
}
</script>

<style lang="scss" scoped>
$sideWidth: 184px;

.episode {
  margin-bottom: $gap * 3;
  display: grid;
  align-items: flex-start;
  grid-column-gap: 24px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr;

  @include display-less(phablet) {
    display: flex;
    flex-direction: column;
  }

  &__play-pause {
    margin-top: auto;
    margin-bottom: auto;
    width: 56px;
    flex-shrink: 0;
    @include display-less(phablet) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__top {
    display: flex;
    gap: $gap;

    @include display-less(phablet) {
      flex-direction: column;
      margin-bottom: $gap;
    }
  }

  &__metadata {
    font-size: 14px;
    display: flex;
    &-episode {
      margin-right: 8px;
      padding-right: 8px;
      border-right: 1px solid #797088;
    }
    &-created {
      display: flex;
      margin-right: 8px;
      padding-right: 8px;
      border-right: 1px solid #797088;
      &-icon {
        margin-right: 5px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    &-countdown {
      display: flex;
      &-icon {
        width: 20px;
        margin-right: 5px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  &__explicit {
    &-mobile {
      display: none;

      &-tag {
        margin-left: 0!important;
        margin-right: 0!important;
      }
      @include display-less(phablet) {
        display: inline-block;
      }
    }

    &-desktop {
      margin-left: 0.5 * $gap;
      &-tag {
        margin-left: 0!important;
        margin-right: 0!important;
      }

      @include display-less(phablet) {
        display: none;
      }
    }
  }

  &__side {
    max-width: $sideWidth;
    float: none;
    margin: 0;

    @include display-less(phablet) {
      order: 1;
      margin-left: auto;
      margin-right: auto;
      max-width: none;
      width: 50%;
    }
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    @include display-less(desktop) {
      display: inline-block;
      max-width: 100%;
      font-size: $fontSizeSmall;
    }

    @include display-less(phablet) {
      order: 2;
      padding: 0;
      display: block;
      width: 100%;
      border-radius: 0;
      margin-top: $gap * 2;
      background: transparent;
      font-size: $fontSizeBase;
    }
  }

  &__title {
    margin: 0;
    color: #fff;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 10px;
    span {
      margin-right: $gap / 2;
    }

    @include display-less(desktop) {
      display: flex;

      span {
        width: 100%;
        margin-right: 0;
        display: inline-block;
      }
    }
  }

  &__sub-title {
    margin: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.5*$gap;

    /deep/ strong {
      color: #fff;
    }
  }

  &__cover {
    position: relative;
    border-radius: 18px;
  }

  &__image {
    z-index: 5;
    object-fit: cover;
    position: relative;
    border-radius: 18px;
    vertical-align: bottom;

    &-blured {
      top: 8px;
      left: 8px;
      z-index: 3;
      object-fit: cover;
      position: absolute;
      filter: blur(16px);
      border-radius: 18px;
      vertical-align: bottom;
    }
  }

  &__share {
    margin-top: $gap;
  }
}
</style>
