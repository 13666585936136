<template lang="pug">
section.s
  ul.s__list
    li.s__item(
      v-for="item in soundbites"
      :key="item"
      @click="skipTo(getStartTime(item))"
      :style="isCurrentSoundbite(getStartTime(item), getEndTime(item)) && { 'border': 'none' }"
    )
      SoundbiteIcon.s__item-icon
      span.s__item-title {{ formatTitle(item.title) }}
      span.s__item-time {{ formatDuration(item) }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { prettifyAudioDuration } from '@/lib/utils.js'

import SoundbiteIcon from '@/components/icons/community/Soundbite'

export default {
  components: {
    SoundbiteIcon
  },

  props: {
    episode: {
      type: Object,
      default: null
    },
    podcast: {
      type: Object,
      default: null
    },
    soundbites: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState('player', ['currentTime', 'status', 'currentEpisodeId'])
  },

  methods: {
    ...mapActions('player', ['setSkipTo', 'setCurrentPlayingPodcast', 'setPlayerStatus', 'setCurrentPlayingEpisode']),

    formatTitle (title) {
      if (!title || title.length < 60) {
        return title
      }

      return title.slice(0, 60) + ' ...'
    },

    formatDuration (soundbite) {
      const startTime = soundbite.startTime
      const endTime = soundbite.endTime
      return `${prettifyAudioDuration(startTime, true)} - ${prettifyAudioDuration(endTime, true)}`
    },

    getStartTime (soundbite) {
      return soundbite.startTime
    },

    getEndTime (soundbite) {
      return soundbite.endTime
    },

    async skipTo (seconds) {
      this.setCurrentPlayingPodcast(this.podcast)
      this.setCurrentPlayingEpisode(this.episode)
      this.setSkipTo(seconds)
      this.setPlayerStatus('playing')

      const playlist = await this.$store.dispatch('podcasts/getPlaylist', this.podcast.slug) || []
      await this.$store.dispatch('player/setPlayList', playlist)
    },

    isCurrentSoundbite (startTime, endTime) {
      return this.status === 'playing' &&
        this.episode.id === this.currentEpisodeId &&
        this.currentTime >= startTime &&
        this.currentTime < endTime
    }
  }
}
</script>

<style lang="scss" scoped>
.s {
  margin-bottom: $gap * 4;

  &__list {
    display: grid;
    grid-gap: $gap;
    grid-template-columns: 1fr 1fr;

    @include display-less(desktop) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    height: 64px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    padding: 0 $gap * 1.5;
    border: 1px solid #F1EFFF;
    transition: background .2s ease;
    background-color: #1D0D33;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color:  #302445;
    }

    &:after {
      position: absolute;
      top: -1px; bottom: -1px;
      left: -1px; right: -1px;
      background: linear-gradient(to bottom right, rgba(248,121,0,1) 0%, rgba(254,79,69,1) 50%, rgba(228,25,186,1) 100%);
      content: '';
      z-index: -1;
      border-radius: 9px;
    }

    @include display-less(desktop) {
      height: auto;
      padding: $gap $gap * 1.5;
    }

    @include display-less(smart) {
      display: grid;
      grid-template-columns: 50px 1fr;
    }

    &-icon {
      flex-shrink: 0;

      @include display-less(smart) {
        grid-row: 1/3;
      }
    }

    &-title {
      color: #fff;
      font-size: 16px;
      line-height: 1.2;
      font-weight: bold;
      margin-left: $gap;
      margin-right: $gap;

      @include display-less(smart) {
        margin: 0;
      }
    }

    &-time {
      color: #fff;
      flex-shrink: 0;
      font-size: 14px;
      font-weight: 500;
      margin-left: auto;

      @include display-less(smart) {
        margin-left: 0;
      }
    }
  }
}
</style>
