<template lang="pug">
  section.transcription
    .transcription_srt(v-if="formattedSrt")
      .transcription__row(
        v-for="row in formattedSrt"
        :class="{'transcription__row-hover': !isCurrentTranscript(row.id, row.startTime, row.endTime)}"
        @click.prevent="skipTo(row.startTime)"
      )
        button.transcription__row-time {{ row.startTime }}
        span.transcription__row-text(
          :class="{'transcription__row-text-blue': isCurrentTranscript(row.id, row.startTime, row.endTime)}"
        ) {{ row.text }}
    .transcription__txt(v-else) {{ transcription }}

</template>

<script>
import { mapState, mapActions } from 'vuex'

import ArrowIcon from '@/components/icons/dashboard/EpisodeItemArrow.vue'

export default {
  components: {
    ArrowIcon
  },

  props: {
    transcription: {
      type: String,
      default: ''
    },
    episode: {
      type: Object,
      default: null
    },
    podcast: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      isOpened: false,
      formattedSrt: null
    }
  },

  computed: {
    ...mapState('player', ['currentTime', 'status', 'currentEpisodeId'])
  },

  mounted () {
    this.formattedSrt = this.formatTranscription(this.transcription)
  },

  methods: {
    ...mapActions('player', ['setSkipTo', 'setCurrentPlayingPodcast', 'setCurrentPlayingEpisode', 'setPlayerStatus']),

    timeToSeconds (time) {
      const splittedTime = time.split(':')
      return parseInt(splittedTime[0]) * 3600 + parseInt(splittedTime[1]) * 60 + parseInt(splittedTime[2])
    },

    async skipTo (time) {
      this.setCurrentPlayingPodcast(this.podcast)
      this.setCurrentPlayingEpisode(this.episode)
      const seconds = this.timeToSeconds(time)
      this.setSkipTo(seconds)
      this.setPlayerStatus('playing')

      const playlist = await this.$store.dispatch('podcasts/getPlaylist', this.podcast.slug) || []
      await this.$store.dispatch('player/setPlayList', playlist)
    },

    isCurrentTranscript (startTime, endTime) {
      return this.status === 'playing' &&
        this.episode.id === this.currentEpisodeId &&
        this.currentTime >= this.timeToSeconds(startTime) &&
        this.currentTime < this.timeToSeconds(endTime)
    },

    formatTranscription (transcription) {
      const splittedTranscript = transcription.split('\n').filter(row => row !== '')
      const formattedTranscription = []

      if (splittedTranscript.length < 3) {
        return null
      }

      for (let i = 0; i + 3 <= splittedTranscript.length; i += 3) {
        const containId = /^\d+$/.test(splittedTranscript[i])
        const containTimestamp = /^\d{2}:\d{2}:\d{2},\d{3} --> \d{2}:\d{2}:\d{2},\d{3}$/.test(splittedTranscript[i + 1])

        if (!containId || !containTimestamp) {
          return null
        }

        formattedTranscription.push({
          id: splittedTranscript[i],
          startTime: splittedTranscript[i + 1].split(',')[0],
          endTime: splittedTranscript[i + 1].split('--> ')[1].split(',')[0],
          text: splittedTranscript[i + 2]
        })
      }

      return formattedTranscription
    }
  }
}
</script>

<style lang="scss" scoped>
.transcription {
  margin-bottom: $gap * 1.5;

  &__txt {
    color: white;
    white-space: pre-line;
  }

  &__row {
    margin-bottom: $gap * 0.75;
    display: flex;
    flex-direction: column;
    width: fit-content;

    &-hover:hover &-text{
      background-color: #797088;
      cursor: pointer;
    }

    &-hover:hover &-time{
      text-decoration: underline;
      cursor: pointer;
    }

    &-time {
      color: #D5BDF6;
      font-size: 14px;
      line-height: 17px;
      background-color: transparent;
      border: none;
      padding-left: 0;
      padding-right: 0;
      text-align: inherit;
      border-radius: 0;
      font-family: inherit;
    }
    &-text {
      color: #fff;
      font-size: 14px;
      line-height: 21px;
      width: fit-content;
      &-blue {
        background-color: #0a5cd7;
      }
    }
  }
}
</style>
