<template lang="pug">
section.c
  ul.c__list
    li.c__item(
      v-for="item in chapters"
      :key="item"
      @click="skipTo(getStartTime(item))"
      :style="isCurrentChapter(getStartTime(item), getEndTime(item)) && { 'border': 'none' }"
    )
      .c__item-img(
          v-if="item.img"
      )
        img(
            :src="getChapterCover(item)"
          )
      .c__item-info
        .c__item-info-title {{ getChapterTitle(item) }}
        .c__item-info-time
          .c__item-info-time-icon
            ClockIcon
          .c__item-info-time-data {{ formatDuration(item) }}
      a.c__item-link(
        v-if="item.url"
        :href="item.url"
        target="_blank"
        rel="noopener nofollow"
        @click.stop
      )
        BTooltip.c__item-link-tooltip(
          :label="$t('coachmarks.visit_url')"
        )
          LinkIcon
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { prettifyAudioDuration } from '@/lib/utils.js'
import { config } from '@/config'

import LinkIcon from '@/components/icons/dashboard/Link'
import ClockIcon from '@/components/icons/dashboard/Clock'

export default {
  components: {
    LinkIcon,
    ClockIcon
  },

  props: {
    episode: {
      type: Object,
      default: null
    },
    podcast: {
      type: Object,
      default: null
    },
    chapters: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapState('player', ['currentTime', 'status', 'currentEpisodeId']),

    podcastSlug () {
      return this.$route.params.slug
    }
  },

  methods: {
    ...mapActions('player', ['setSkipTo', 'setCurrentPlayingPodcast', 'setCurrentPlayingEpisode', 'setPlayerStatus']),

    getChapterTitle (chapter) {
      if (!chapter.title) {
        return ''
      }

      if (chapter.title.length < 60) {
        return chapter.title
      }

      return chapter.title.slice(0, 60) + ' ...'
    },

    formatDuration (chapter) {
      const startTime = chapter.startTime
      const endTime = chapter.endTime
      return `${prettifyAudioDuration(startTime, true)} - ${prettifyAudioDuration(endTime, true)}`
    },

    getChapterCover (chapter) {
      const coverUrl = chapter.img
      const coverFilename = coverUrl.split('/')[4]
      const resizedCoverUrl = `${config.imageResizerUrl}/${this.podcastSlug}/80/${coverFilename}`
      return resizedCoverUrl
    },

    getStartTime (chapter) {
      return chapter.startTime
    },

    getEndTime (chapter) {
      return chapter.endTime
    },

    async skipTo (seconds) {
      this.setCurrentPlayingPodcast(this.podcast)
      this.setCurrentPlayingEpisode(this.episode)
      this.setSkipTo(seconds)
      this.setPlayerStatus('playing')

      const playlist = await this.$store.dispatch('podcasts/getPlaylist', this.podcast.slug) || []
      await this.$store.dispatch('player/setPlayList', playlist)
    },

    isCurrentChapter (startTime, endTime) {
      return this.status === 'playing' &&
        this.episode.id === this.currentEpisodeId &&
        this.currentTime >= startTime &&
        this.currentTime < endTime
    }
  }
}
</script>

<style lang="scss" scoped>
.c {
  margin-bottom: $gap * 3.5;

  &__list {
    display: flex;
    flex-direction: column;
    gap: $gap * 1.5;
  }

  &__item {
    display: flex;
    gap: $gap;
    min-height: 74px;
    border-radius: 8px;
    border: 1px solid #F1EFFF;
    background-color: #1D0D33;
    padding: 16px;
    transition: background .2s ease;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color:  #302445;
    }

    &:after {
      position: absolute;
      top: -1px; bottom: -1px;
      left: -1px; right: -1px;
      background: linear-gradient(to bottom right, rgba(248,121,0,1) 0%, rgba(254,79,69,1) 50%, rgba(228,25,186,1) 100%);
      content: '';
      z-index: -1;
      border-radius: 9px;
    }

    &-img {
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      img {
        border-radius: 4px;
      }

    }

    &-info {
      display: flex;
      flex-direction: column;
      gap: $gap * 0.25;
      color: #fff;
      padding: 0;
      height: 100%;
      &-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 19px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        @include display-less(phablet) {
          -webkit-line-clamp: 3;
        }
      }

      &-time {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gap * 0.25;
        font-size: 14px;
        align-self: flex-start;
        &-icon {
          margin-right: 1px;
          height: 20px;
          width: 20px;
        }
        &-data {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    &-link {
      margin-left: auto;
    }
  }
}
</style>
